@font-face {
  font-family: 'Juana Alt';
  src: url('JuanaFSAlbert/JuanaAltRegular-Regular.woff2') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FS Albert Pro';
  src: url('JuanaFSAlbert/FSAlbertProRegular.woff2') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FS Albert Pro';
  src: url('JuanaFSAlbert/FSAlbertProBold-Bold.woff2') format('truetype');
  font-weight: 700;
  font-style: normal;
}
