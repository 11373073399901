html,
body {
  padding: 0;
  margin: 0;
  height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: 'FS Albert Pro Latin', sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent;
}

:root {
  --blue: #2146af;
  --blue-light: #f2f8fd;
  --blue-dark: #061a51;
  --grey-dark: #3d3d4e;
  --grey-block: #f4f4f5;
  --grey-border: #26283029;
  --grey-subtitle: #71717a;
  --light-grey: #dcdcde;
  --black: #27262b;
  --black-title: #28262b;
  --orange-light: #fff7ee;
  --white: #fff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

html,
body {
  max-width: 100vw;
  color: var(--black);
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1;
}

ul[class],
ol[class] {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
/*@media (prefers-reduced-motion: reduce) {*/
/*  * {*/
/*    animation-duration: 0.01ms !important;*/
/*    animation-iteration-count: 1 !important;*/
/*    transition-duration: 0.01ms !important;*/
/*    scroll-behavior: auto !important;*/
/*  }*/
/*}*/
